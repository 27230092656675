import OpenSeadragon from 'openseadragon';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as FlatModels from '@medsurf/flat-models';
import { ImageViewerFacade, MenuViewerFacade, NodeSharedFacade, SlideViewerFacade } from '@medsurf/flat-facades';
import { MediaControlService } from '@medsurf/flat-services';
import { Scalebar } from './elements/scalebar';
import { Container } from './elements/container';
import { Overlay } from './elements/overlay';
import { VirtualPointer } from './elements/tools/virtualPointer';
import { Marker } from './elements/marker/marker';
import { Freeform } from './elements/freeform/freeform';
import { Keymap } from './elements/marker/keymap';
import { Text } from './elements/marker/text';
import { Format } from '../../../common/models/format';
import { Command } from './tools/command';
import { MarkerTool } from './tools/markerTool';
import { onFontLoad } from '@medsurf/helpers';
import { isEqual } from 'lodash';
import { ZipZoomify } from "./osd/ZipZoomify";

@Component({
  selector: 'medsurf-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements AfterViewInit, OnDestroy {
  /**
   * View Children
   */
  @ViewChild('btnGroup', {static: false}) public btnGroupRef: ElementRef;
  @ViewChild('imageElement', {static: false}) public imageElementRef: ElementRef;

  /**
   * Members
   */
  private _destroyed$ = new Subject<boolean>();
  public showSpinner = true;
  public resetViewport = false;
  public viewer: OpenSeadragon.Viewer | null = null;
  public overlay: Overlay | null = null;
  public scalebar: Scalebar | null = null;
  public virtualPointer: VirtualPointer | null = null;
  public container: Container | null = null;
  public navigatorNode: HTMLElement | null = null;
  public imageCount: number;
  public imageIndex: number;
  public slide: FlatModels.PageEntityModels.Slide;
  public image: FlatModels.ImageEntityModels.Image;
  public media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom;
  public annotations: FlatModels.AnnotationEntityModels.Annotation[];
  public selectedPoi: FlatModels.PointOfInterestEntityModels.PointOfInterest;
  public minZoom = 0;
  public mouseOverCanvas = false;
  public preloadTimers: NodeJS.Timeout[] = [];
  public virtualPointerSelected = false;

  /**
   * Constructor
   *
   * @param slideViewerFacade: SlideViewerFacade
   * @param imageViewerFacade: ImageViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   * @param mediaControlService: MediaControlService
   * @param zone: NgZone
   * @param translate: TranslateService
   * @param http: HttpClient
   * @param router: Router
   */
  public constructor(public slideViewerFacade: SlideViewerFacade,
              public imageViewerFacade: ImageViewerFacade,
              public menuViewerFacade: MenuViewerFacade,
              public nodeSharedFacade: NodeSharedFacade,
              public mediaControlService: MediaControlService,
              public zone: NgZone,
              public translate: TranslateService,
              public http: HttpClient,
              public router: Router) {
  }

  /**
   * Ng After View Init
   */
  public ngAfterViewInit(): void {
    this.nodeSharedFacade.currentSelectedSettings$.pipe(takeUntil(this._destroyed$), delay(1)).subscribe((settings) => {
      if (settings.hideAnnotations !== null) {
        this.menuViewerFacade.requestToggleMarker(!settings.hideAnnotations);
      }
    })

    this.slideViewerFacade.currentSelectedSlideValidImages$.pipe(takeUntil(this._destroyed$)).subscribe((images) => {
      this.imageCount = images.length;
    })
    this.slideViewerFacade.imageIndex$.pipe(takeUntil(this._destroyed$)).subscribe((imageIndex) => {
      this.imageIndex = imageIndex;
      this.updateLayerPreload(this.imageIndex, false);
    });
    this.slideViewerFacade.currentSelectedSlideAreaGroup$.pipe(takeUntil(this._destroyed$)).subscribe(this.onSelectedAreaGroupChange.bind(this));
    this.slideViewerFacade.currentSelectedSlideGroup$.pipe(takeUntil(this._destroyed$)).subscribe(this.onSelectedGroupChange.bind(this));

    this.slideViewerFacade.currentSelectedSlidePoi$.pipe(takeUntil(this._destroyed$)).subscribe((poi) => {
      this.selectedPoi = poi;
      this.onSelectedPoiChange(poi, this.media);
    });
    this.slideViewerFacade.currentSelectedSlideAnnotation$.pipe(takeUntil(this._destroyed$)).subscribe(this.onSelectedAnnotationChange.bind(this));

    this.imageViewerFacade.config$.pipe(takeUntil(this._destroyed$)).subscribe((config) => {
      this.initOpenSeadragonViewer(config);
    });
    this.imageViewerFacade.slide$.pipe(takeUntil(this._destroyed$)).subscribe((slide) => {
      this.slide = slide;
      this.resetViewport = true;
    });
    this.imageViewerFacade.image$.pipe(takeUntil(this._destroyed$)).subscribe((image) => {
      this.image = image;
    });
    this.imageViewerFacade.medias$.pipe(
      takeUntil(this._destroyed$),
      distinctUntilChanged((a, b) => isEqual(a, b))
    ).subscribe((medias) => {
      if (this.container) {
        this.container.destroyElements();
      }
      this.initOpenSeadragonImages(medias, this.slide, this.imageIndex);
      if (medias?.length > 0) {
        this.initOpenSeadragonAnnotations(this.overlay, this.container, this.slide, this.media, this.annotations);
      }
    })
    this.imageViewerFacade.media$.pipe(takeUntil(this._destroyed$)).subscribe((media) => {
      this.media = media;
      this.initOpenSeadragonImage(this.image, this.media, this.slide);
    });
    this.imageViewerFacade.annotations$.pipe(
      takeUntil(this._destroyed$), 
      distinctUntilChanged((a, b) => isEqual(a, b))
    ).subscribe((annotations) => {
      this.annotations = annotations;
      if (this.container) {
        this.container.destroyElements();
      }
      this.initOpenSeadragonAnnotations(this.overlay, this.container, this.slide, this.media, this.annotations);
    });

    this.menuViewerFacade.showVirtualPointer$.pipe(takeUntil(this._destroyed$)).subscribe(this.toggleVirtualPointer.bind(this));
    this.menuViewerFacade.showMarkers$.pipe(takeUntil(this._destroyed$)).subscribe(this.toggleMarkers.bind(this));
    this.menuViewerFacade.showSelftest$.pipe(takeUntil(this._destroyed$)).subscribe(this.toggleSelftest.bind(this));

    window.addEventListener('wheel', this.handleWheel.bind(this), { passive: false});
  }

  /**
   * Update Layer Preload
   *
   * @param imageIndex: number
   * @param preload: boolean
   */
  private updateLayerPreload(imageIndex: number, preload: boolean) {
    if (this.preloadTimers.length > 0) {
      this.preloadTimers.forEach(timer => clearTimeout(timer));
    }
    if (imageIndex === undefined || !this.viewer) {
      return;
    }
    const length: number = this.viewer.world.getItemCount();
    for (let i = 0; i < length; i++) {
      const item = this.viewer.world.getItemAt(i);
      if (i === imageIndex) {
        item.setOpacity(1);
        item.immediateRender = false;
      } else if (preload && (i === imageIndex + 1 || i === imageIndex - 1)) {
        this.preloadTimers.push(setTimeout(() => {
          item.setOpacity(0.001); // setting opacity to not zero enables preloading of the viewport
          item.immediateRender = true;
        }, 100));
      } else {
        if (item.getOpacity() !== 0) {
          item.setOpacity(0);
          item.immediateRender = true;
        }
      }
    }
  }

  /**
   * Init Open Seadragon Viewer
   *
   * @param config: Partial<OpenSeadragon.Options>
   */
  public async initOpenSeadragonViewer(config: Partial<OpenSeadragon.Options>) {
    if (config !== null && this.imageElementRef && this.imageElementRef.nativeElement) {
      // Init Viewer
      await this.zone.runOutsideAngular(async () => {
        // Destroy Viewer
        if (this.viewer !== null) {
          this.viewer.destroy();
        }
        // Create Viewer
        this.viewer = new OpenSeadragon.Viewer(Object.assign(config, {element: this.imageElementRef.nativeElement}));

        // Setup Viewer Handlers
        this.setupViewerHandlers();

        // Setup Overlay
        this.overlay = new Overlay(this.viewer);

        // Setup Scalebar
        this.scalebar = new Scalebar({
          viewer: this.viewer,
          backgroundColor: 'white',
          xOffset: 10,
          yOffset: 10,
          barThickness: 4,
        });

        // Setup Container
        this.container = new Container(this.overlay.localPaper, new Format());

        // Setup Navigator
        this.navigatorNode = this.viewer?.navigator?.element?.parentElement;

        // Show navigator
        this.setNavigatorNode();

        // Setup Tools
        await this.setupTool(this.overlay, this.container);
      });
    }
    return true;
  }

  /**
   * Add Viewer Handlers
   */
  public setupViewerHandlers() {
    this.viewer.removeAllHandlers();

    this.viewer.addHandler('open', () => {
      this.showSpinner = false;
    });

    this.viewer.addHandler('animation-start', () => {
      this.updateLayerPreload(this.imageIndex, false);
    })

    this.viewer.addHandler('animation-finish', () => {
      this.updateLayerPreload(this.imageIndex, true);
    })

    this.viewer.addHandler('canvas-enter', () => {
      this.mouseOverCanvas = true;
      this.viewer.canvas.focus();
    });

    this.viewer.addHandler('canvas-exit', () => {
      this.mouseOverCanvas = false;
      this.viewer.canvas.blur();
    });

    this.viewer.addHandler('animation', () => {
      if (this.virtualPointer) {
        this.virtualPointer.arrange();
      }
    });

    onFontLoad('Figtree', { weight: '600'}, () => {
      this.container?.arrange();
      this.container?.update();
    })
  }

  /**
   * Creates a placeholder blank image
   *
   * @param width: image width
   * @param height: image height
   */
  public getPlaceholderImageUrl(width: number, height: number) {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const placeholderUrl = canvas.toDataURL();
    return placeholderUrl;
  }

  /**
   * Init Open Seadragon Images
   *
   * @param medias: FlatModels.MediaEntityModels.MediaImage[] | FlatModels.MediaEntityModels.MediaDeepzoom[]
   * @param slide: FlatModels.PageEntityModels.Slide
   * @param imageIndex: number
   */
  public initOpenSeadragonImages(medias: FlatModels.MediaEntityModels.MediaImage[] | FlatModels.MediaEntityModels.MediaDeepzoom[],
    slide: FlatModels.PageEntityModels.Slide,
    imageIndex: number) {
    if (this.viewer !== null && Array.isArray(medias)) {

      const media = medias.find(m => this.mediaControlService.isImage(m) || this.mediaControlService.isDeepzoom(m));
      const placeholderUrl = this.getPlaceholderImageUrl(media.dimensions.width / 10, media.dimensions.height / 10);

      // Show Spinner
      this.showSpinner = true;

      // Setup Tile Sources
      const tileSources: any = [];
      medias.forEach((media, index) => {
        // Get Dimensions
        const dimensions = media && media.dimensions;

        // Get isActive
        const isActive = index === imageIndex;

        // Get isPreload
        const isPreload = index === (imageIndex - 1) || index === (imageIndex + 1);

        // Tile Sources
        let tileSource;
        let loadTilesWithAjax = false;
        if (media.type === FlatModels.MediaEntityModels.MediaType.DEEPZOOM) {
          const tilesUrl = this.mediaControlService.getUrl(media, 'tileFolder');
          if (tilesUrl.endsWith('.zip')) {
            loadTilesWithAjax = true;
            tileSource = new ZipZoomify({
              tilesUrl,
              width: (dimensions?.width || 0),
              height: (dimensions?.height || 0),
            })
          } else {
            tileSource = {
              type: 'zoomifytileservice',
              tilesUrl,
              width: (dimensions?.width || 0),
              height: (dimensions?.height || 0),
              tileSize: (dimensions?.tileSize || 0)
            }
          }
        } else if (media.type === FlatModels.MediaEntityModels.MediaType.IMAGE) {
          tileSource = {
            type: 'image',
            url: this.mediaControlService.getUrl(media)
          }
        } else {
          tileSource = {
            type: 'image',
            url: placeholderUrl
          }
        }
        tileSources.push({
          x: 0,
          y: 0,
          loadTilesWithAjax,
          opacity: isActive ? 1 : (isPreload ? 0.001 : 0),
          tileSource
        });
      });
      this.viewer.open(tileSources);
      if (this.resetViewport) {
        this.viewer.addOnceHandler('open', () => {
          this.viewer.viewport.goHome(true);
        })
        this.resetViewport = false;
      }
    }
  }



  /**
   * Init Open Seadragon Image
   *
   * @param image: FlatModels.ImageEntityModels.Image
   * @param media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom
   * @param slide: FlatModels.PageEntityModels.Slide
   */
  public initOpenSeadragonImage(image: FlatModels.ImageEntityModels.Image,
                                media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom,
                                slide: FlatModels.PageEntityModels.Slide) {
    if (this.viewer !== null && media) {
      // Navigator Background Color
      const navigatorBackgroundColor = image && image.background && image.background.color || '';

      // Setup Background Color
      this.viewer.element.style.backgroundColor = navigatorBackgroundColor.startsWith('#') ? navigatorBackgroundColor : '#ffffff';

      // Get Dimensions
      const dimensions = media && media.dimensions;

      // Update Scalebar
      this.scalebar.updateOptions({
        pixelsPerMeter: (dimensions.pixelPerMillimeter ? (dimensions.pixelPerMillimeter * 1000) : null)
      });
      this.scalebar.refresh();

      // Update Container
      this.updateContainer(media, slide);

      // Update Min Zoom
      this.minZoom = this.viewer.viewport.getZoom(true);

      this.toggleMarkers(this.menuViewerFacade.snapshot_showMarkers());
      this.toggleSelftest(this.menuViewerFacade.snapshot_showSelftest());
    }
  }

  /**
   * Setup Container
   *
   * @param media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom
   * @param slide: FlatModels.PageEntityModels.Slide
   */
  public updateContainer(media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom,
                         slide: FlatModels.PageEntityModels.Slide) {
    const dimensions = this.getDimensions(media);
    this.container.setCanvasSize(
      dimensions.width,
      dimensions.height
    );
    this.container.setMinZoom(1);
    this.container.groups = slide.groups;
  }

  /**
   * Get Dimensions
   *
   * @param media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom
   */
  public getDimensions(media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom): FlatModels.MediaEntityModels.Dimensions {
    return media.dimensions;
  }

  /**
   * Zoom In
   */
  public zoomIn(): void {
    const zoomInStep = 1.2;
    const maxZoom: number = this.viewer.viewport.getMaxZoom();
    const currentZoom: number = this.viewer.viewport.getZoom(true);
    if (maxZoom > currentZoom) {
      this.viewer.viewport.zoomBy(zoomInStep, null, true);
    }
  }

  /**
   * Toggle Navigator
   */
  public toggleNavigator() {
    this.menuViewerFacade.requestToggleNavigator();
    this.setNavigatorNode();
  }

  /**
   * Zoom Out
   */
  public zoomOut() {
    const zoomOutStep = 0.8;
    const currentZoom: number = this.viewer.viewport.getZoom(true);
    if (this.minZoom < currentZoom) {
      this.viewer.viewport.zoomBy(zoomOutStep, null, true);
    }
  }

  /**
   * Set Navigator Node
   *
   * @private
   */
  private setNavigatorNode() {
    if (this.navigatorNode) {
      this.navigatorNode.style.display = this.menuViewerFacade.snapshot_showNavigator() ? 'inline-block' : 'none';
    }
  }

  /**
   * Init Open Seadragon Annotations
   *
   * @param overlay: Overlay
   * @param container: Container
   * @param slide: FlatModels.PageEntityModels.Slide
   * @param media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom
   * @param annotations: FlatModels.AnnotationEntityModels.Annotation[]
   */
  public initOpenSeadragonAnnotations(overlay: Overlay,
                                      container: Container,
                                      slide: FlatModels.PageEntityModels.Slide,
                                      media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom,
                                      annotations: FlatModels.AnnotationEntityModels.Annotation[]) {
    if (!overlay || !container || !annotations || !media) {
      return;
    }
    const {localPaper, mainLayer} = overlay;
    const scale = this.mediaControlService.isDeepzoom(media) ? 1 : 600 / media.dimensions.width;
    mainLayer.visible = false;
    // const offset = (isVM) ? undefined : this.slide.images[this.sequenceId].offset;
    annotations.forEach((marker: FlatModels.ImageViewerModels.ElementModels) => {
      const markerFormat = new Format(this.slide?.defaults || {});
      let element;
      switch (marker.annotation.type) {
        case FlatModels.AnnotationEntityModels.AnnotationType.TEXT:
          element = new Text(marker, markerFormat, localPaper, mainLayer, container, scale, {x: 0, y: 0});
          break;
        case FlatModels.AnnotationEntityModels.AnnotationType.KEYMAP:
          element = new Keymap(marker as FlatModels.ImageViewerModels.KeymapModel, markerFormat, localPaper, mainLayer, container, scale, {x: 0, y: 0});
          break;
        case FlatModels.AnnotationEntityModels.AnnotationType.FREE_FORM:
          markerFormat.setMarkerFormat(marker);
          element = new Freeform(marker as FlatModels.ImageViewerModels.FreeformModel, markerFormat, localPaper, mainLayer, container, scale, {x: 0, y: 0});
          break;
        default:
          markerFormat.setMarkerFormat(marker);
          element = new Marker(marker as FlatModels.ImageViewerModels.MarkerModel, markerFormat, localPaper, mainLayer, container, scale, {x: 0, y: 0});
          break;
      }
      if (element) {
        container.addElement(element);
      }
    });

    // Setup Virtual Pointer
    this.setupVirtualPointer(overlay, container);

    // Resize Overlay
    overlay.resize();
    overlay.resizeCanvas();

    // Show Overlay
    this.viewer.addOnceHandler('update-viewport', () => {
      mainLayer.visible = true;
      localPaper.view.update();
    });
    this.viewer.forceRedraw();

    this.onSelectedGroupChange(this.slideViewerFacade.snapshop_currentSelectedSlideGroup());
    this.toggleMarkers(this.menuViewerFacade.snapshot_showMarkers());
    this.toggleSelftest(this.menuViewerFacade.snapshot_showSelftest());
  }

  /**
   * Setup Virtual Pointer
   *
   * @param overlay: Overlay
   * @param container: Container
   */
  public setupVirtualPointer(overlay: Overlay, container: Container) {
    const {localPaper, mainLayer}: Overlay = overlay;

    if (this.virtualPointer) {
      this.virtualPointer.cleanUp();
      delete this.virtualPointer;
    }

    this.virtualPointer = new VirtualPointer(this.http, localPaper, mainLayer, container);
    this.virtualPointer.id = 'virtualPointer';
    this.virtualPointer.on('created', () => {
      this.virtualPointer.state = this.menuViewerFacade.snapshot_showVirtualPointer() ? 'visible' : 'hidden';
      container.addElement(this.virtualPointer);
      overlay.resize();
      overlay.resizeCanvas();
    });

    this.virtualPointer.loadFile();
  }

  /**
   * Setup Tool
   *
   * @param overlay: Overlay
   * @param container: Container
   * @private
   */
  public async setupTool(overlay: Overlay, container: Container) {
    const {localPaper} = overlay;

    const command = new Command(window);
    const markerTool = new MarkerTool(localPaper, container, window, this.slide, command);
    const trackerConfig = {
      element: this.viewer.canvas,
      pressHandler: (event) => this.clickHandler(this.viewer, event, markerTool),
      dragHandler: (event) => this.dragHandler(this.viewer, event, markerTool),
      releaseHandler: (event) => this.releaseHandler(this.viewer, event, markerTool)
    };
    const mouseTracker: OpenSeadragon.MouseTracker = new OpenSeadragon.MouseTracker(trackerConfig);
    mouseTracker.setTracking(true);

    markerTool.removeAllListeners();
    markerTool.on('unoverMarker', (): void => {
      if (!this.menuViewerFacade.snapshot_showSelftest()) {
        this.zone.run(() => {
          this.slideViewerFacade.requestSetGroupId(null);
          this.slideViewerFacade.requestSetAreaGroupId(null);
        });
        container.arrange();
        container.update();
      }
      this.overlay.canvas.parentElement.style.cursor = 'default';
    });
    markerTool.on('overMarker', () => {
      this.overlay.canvas.parentElement.style.cursor = 'pointer';
    });
    markerTool.on('selectMarker', (event) => {
      if (this.menuViewerFacade.snapshot_showSelftest()) {
        if (typeof event.hitResult.item.parent?.onClick === 'function') {
          // Timer to wait for update of interactive areas
          setTimeout(() => {
            event.hitResult.item.parent.onClick({target: {_id: event.hitResult.item.parent._id}});
            localPaper.view.update();
          }, 100);
        }
      }
    });
    markerTool.on('unselectMarker', () => {
      container.updateMarkerSizes();
      if (this.menuViewerFacade.snapshot_showSelftest()) {
        this.zone.run(() => {
          this.slideViewerFacade.requestSetGroupId(null);
          this.slideViewerFacade.requestSetAreaGroupId(null);
        });
        container.arrange();
        container.update();
      }
      this.handleUnselectVirtualPointer(container);
    });
    markerTool.on('overKeymap', () => {
      this.overlay.canvas.parentElement.style.cursor = 'default';
    });
    markerTool.on('overVirtualPointer', () => {
      this.overlay.canvas.parentElement.style.cursor = 'default';
    });
    markerTool.on('selectVirtualPointer', (event) => {
      this.handleSelectVirtualPointerElement(container, event);
    });
    markerTool.on('updateVirtualPointer', () => {
      container.arrange();
      container.update();
    });
    markerTool.on('unselectVirtualMarker', () => {
      this.handleUnselectVirtualPointer(container);
    });
    markerTool.on('overFreeform', (event) => {
      if (!this.menuViewerFacade.snapshot_showSelftest() && (event.item?.model?.annotation as FlatModels.AnnotationEntityModels.FreeForm)?.shape === FlatModels.AnnotationEntityModels.Shape.INTERACTIVE_AREA) {
        const areaGroupId = this.slideViewerFacade.snapshop_areaGroupId();
        if (areaGroupId !== event.item.model.id) {
          this.zone.run(() => {
            this.slideViewerFacade.requestSetAreaGroupId(event.item.model.id);
          })
        }
      }
      if (event.item.model?.link?.link) {
        this.overlay.canvas.parentElement.style.cursor = 'pointer';
      } else {
        this.overlay.canvas.parentElement.style.cursor = 'default';
      }
    });
    markerTool.on('selectFreeform', (event) => {
      event.event.event.stopImmediatePropagation();
      if (this.menuViewerFacade.snapshot_showSelftest() &&
        ([FlatModels.AnnotationEntityModels.Shape.INTERACTIVE_AREA, FlatModels.AnnotationEntityModels.Shape.ARROW,
          FlatModels.AnnotationEntityModels.Shape.TRIANGLE, FlatModels.AnnotationEntityModels.Shape.RECTANGLE,
          FlatModels.AnnotationEntityModels.Shape.ELLIPSE]
          .includes((event.item.model.annotation as FlatModels.AnnotationEntityModels.FreeForm).shape))
      ) {
        this.zone.run(() => {
          this.slideViewerFacade.requestSetAreaGroupId(event.item.model.id);
        });
      }
      if (event.item._model.link?.link) {
        if (event.item._model.link.type === FlatModels.LinkEntityModels.LinkType.SLIDE) {
          this.goToLink(event.item._model.link.link);
          this.overlay.canvas.parentElement.style.cursor = 'default';
        } else if (event.item._model.link.type === FlatModels.LinkEntityModels.LinkType.SITE) {
          this.goToExternalLink(event.item._model.link.link);
          this.overlay.canvas.parentElement.style.cursor = 'default';
        }
      }
    });
    markerTool.on('overImage', () => {
      if (this.image?.link?.link) {
        this.overlay.canvas.parentElement.style.cursor = 'pointer';
      } else {
        this.overlay.canvas.parentElement.style.cursor = 'default';
      }
    });
    markerTool.on('selectImage', () => {
      if (!this.image?.link?.link) {
        return;
      }
      if (this.image.link.type === FlatModels.LinkEntityModels.LinkType.SLIDE) {
        this.goToLink(this.image.link.link);
      } else if (this.image.link.type === FlatModels.LinkEntityModels.LinkType.SITE) {
        this.goToExternalLink(this.image.link.link);
      }
      this.overlay.canvas.parentElement.style.cursor = 'default';
    });
  }

  /**
   * Click Handler
   *
   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {OpenSeadragon.Viewport} viewer.viewport - OSD viewport object
   * @param {Object} event - OSD event object
   * @param {OpenSeadragon.Point} event.position - The position of the event relative to the tracked element.
   * @param {PointerEvent} event.originalEvent - PointerEvent instance with infos about pressed keys
   * @param {MarkerTool} markerTool - Paper marker tool
   * @private
   */
  private clickHandler(viewer,
                       event,
                       markerTool) {
    const point = new OpenSeadragon.Point(event.position.x, event.position.y);
    const viewportPoint = viewer.viewport.pointFromPixel(point);

    const currentImage = viewer.world.getItemAt(this.imageIndex || 0);
    const imagePoint = currentImage?.viewportToImageCoordinates(viewportPoint);
    if (!imagePoint) {
      return;
    }
    event.point = {};
    ({x: event.point.x, y: event.point.y} = imagePoint);
    event.event = event.originalEvent;

    markerTool._handleMouseDown.call(markerTool._tool, event);
  }

  /**
   * Drag Handler
   *
   * @param {OpenSeadragon.Viewer} viewer - OSD viewer object
   * @param {OpenSeadragon.Viewport} viewer.viewport - OSD viewport object
   * @param {Object} event - OSD event object
   * @param {OpenSeadragon.Point} event.delta - Difference between the current position and the last drag event position.
   * @param {PointerEvent} event.originalEvent - PointerEvent instance with infos about pressed keys
   * @param {MarkerTool} markerTool - Paper marker tool
   * @private
   */
  private dragHandler(viewer, event, markerTool) {
    if (!this.slideViewerFacade.snapshot_currentSelectedSlideAnnotation() && !this.virtualPointerSelected) {
      return;
    }
    const delta = new OpenSeadragon.Point(event.delta.x, event.delta.y);
    const viewportPoint = viewer.viewport.deltaPointsFromPixels(delta);
    viewportPoint.y *= viewer.viewport._contentAspectRatio || 1;
    ({x: event.delta.x, y: event.delta.y} = viewportPoint);
    event.event = event.originalEvent;

    markerTool._handleMouseDrag.call(markerTool._tool, event);
  }

  /**
   * Handle Select Virtual Pointer Element
   *
   * @param {Container} container
   * @param {Object} event
   * @param {Model} event.item
   * @private
   */
  private handleSelectVirtualPointerElement(container, event) {
    if (container) {
      if (event.item) {
        this.virtualPointerSelected = true;
        this.viewer.setMouseNavEnabled(false);
        const element = container.getElementById(event.item._id);
        if (element) {
          container.select(element, false);
        }
      }
    }
  }

  /**
   * Handle Unselect Virtual Pointer
   *
   * @param {Container} container
   * @private
   */
  private handleUnselectVirtualPointer(container) {
    this.virtualPointerSelected = false;
    container.deselectAll();
    this.slideViewerFacade.requestSetAnnotationId(null);
    this.viewer.setMouseNavEnabled(true);
  }

  /**
   * Release Handler
   *
   * @param viewer
   * @param event
   * @param markerTool
   * @private
   */
  private releaseHandler(viewer, event, markerTool) {
    if (!this.slideViewerFacade.snapshot_currentSelectedSlideAnnotation() && !this.virtualPointerSelected) {
      return;
    }
    markerTool._handleMouseUp.call(markerTool._tool, event);
  }

  /**
   * Toggle Virtual Pointer
   */
  public toggleVirtualPointer(showVirtualPointer?: boolean) {
    if (!this.virtualPointer) {
      return;
    }
    if (showVirtualPointer) {
      this.virtualPointer.state = 'visible';
      const currentImage = this.viewer.world.getItemAt(this.imageIndex || 0);
      if (currentImage) {
        const {x, y} = this.viewer.viewport.getCenter();
        const offset = currentImage.viewportToImageCoordinates(x, y);
        this.virtualPointer.model.offset.x = offset.x;
        this.virtualPointer.model.offset.y = offset.y;
        this.virtualPointer.arrange();
      }
    } else {
      this.virtualPointer.state = 'hidden';
    }
    this.overlay.resize();
    this.overlay.resizeCanvas();
  }

  /**
   * Toggle Markers
   *
   * @param showMarkers: boolean
   */
  private toggleMarkers(showMarkers?: boolean): void {
    if (!this.container) {
      return;
    }
    if (showMarkers) {
      this.container.showSelftest(false);
    }
    if (!this.menuViewerFacade.snapshot_showSelftest()) {
      this.container.showMarkers(showMarkers);
    }
    this.container.arrange();
    this.container.update();
  }

  /**
   * Toggle Selftest
   *
   * @param showSelftest: boolean
   */
  private toggleSelftest(showSelftest: boolean): void {
    if (!this.container) {
      return;
    }
    if (showSelftest) {
      this.container.showMarkers(false);
    }
    if (!this.menuViewerFacade.snapshot_showMarkers()) {
      this.container.showSelftest(showSelftest);
    }
    this.container.arrange();
    this.container.update();
  }

  /**
   * On Selected Virtual Group Change
   *
   * @param group: FlatModels.GroupEntityModels.Group
   * @private
   */
  private onSelectedAreaGroupChange(group: FlatModels.GroupEntityModels.Group) {
    if (!this.container) {
      return;
    }

    const showSelftest = this.menuViewerFacade.snapshot_showSelftest();
    const showMarkers = this.menuViewerFacade.snapshot_showMarkers();

    this.container.elements.forEach((el) => {
      if (el.model?.id && el.model?.annotation?.shape === FlatModels.AnnotationEntityModels.Shape.INTERACTIVE_AREA) {
        const ignoreSelftest = el.model?.annotation?.selfTest?.ignore || false;
        if (group) {
          if (group?.annotations.findIndex(a => a as unknown as string === el.model.id) > -1) {
            el.state = 'visible';
            el._labelVisible = true;
            return;
          }

          if (showSelftest && ignoreSelftest) {
            el.state = 'visble';
            el._labelVisible = true;
            return;
          }

          if (showSelftest) {
            el.state = 'visble';
            el._labelVisible = false;
            return;
          }

          if (!showMarkers) {
            el.state = 'hidden';
            el._labelVisible = false;
            return;
          }

          el.state = 'opaque';
          el._labelVisible = false;
          return;
        }

        if (showSelftest) {
          el.state = 'visible';
          el._labelVisible = ignoreSelftest;
          return;
        }

        if (!showMarkers) {
          el.state = 'hidden';
          el._labelVisible = true;
          return;
        }

        el.state = 'visible';
        el._labelVisible = true;
      }
    });
    this.container.arrange();
    this.container.update();
  }

  /**
   * On Selected Group Change
   *
   * @param group: FlatModels.GroupEntityModels.Group
   * @private
   */
  private onSelectedGroupChange(group: FlatModels.GroupEntityModels.Group) {
    if (!this.container) {
      return;
    }

    const showSelftest = this.menuViewerFacade.snapshot_showSelftest();
    const showMarkers = this.menuViewerFacade.snapshot_showMarkers();

    this.container.elements.forEach((el) => {
      if (el.model?.id) {
        const ignoreSelftest = el.model?.annotation?.selfTest?.ignore || false;
        if (group) {
          // Legacy Feature: Annotations should only be considered in the same group if they are assigned to the group, but not if they have the same color
          if (
            group?.annotations.findIndex(a => a as unknown as string === el.model.id) > -1 ||
            (group?.color && (group.color === el.model?.freeFormStyle?.strokeColor || group.color === el?.model?.freeFormStyle?.color))
          ) {
            el.state = 'visible';
            el._labelVisible = true;
            return;
          }

          if (showSelftest && ignoreSelftest) {
            el.state = 'visble';
            el._labelVisible = true;
            return;
          }

          if (showSelftest) {
            el.state = 'visble';
            el._labelVisible = false;
            return;
          }

          if (!showMarkers) {
            el.state = 'hidden';
            el._labelVisible = false;
            return;
          }

          el.state = 'opaque';
          el._labelVisible = false;
          return;
        }

        if (showSelftest) {
          el.state = 'visible';
          el._labelVisible = ignoreSelftest;
          return;
        }

        if (!showMarkers) {
          el.state = 'hidden';
          el._labelVisible = true;
          return;
        }

        el.state = 'visible';
        el._labelVisible = true;
      }
    });
    this.container.arrange();
    this.container.update();
  }

  /**
   * On Selected Poi Change
   * Sets the Viewport to the selected POI
   *
   * @param poi: FlatModels.PointOfInterestEntityModels.PointOfInterest
   * @param media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom
   */
  public onSelectedPoiChange(poi: FlatModels.PointOfInterestEntityModels.PointOfInterest,
                             media: FlatModels.MediaEntityModels.MediaImage | FlatModels.MediaEntityModels.MediaDeepzoom) {
    if (!poi && !media) {
      return;
    }
    const currentImage = this.viewer?.world.getItemAt(this.imageIndex || 0);
    const { width, height } = this.getDimensions(media);
    if (!poi || !currentImage || !this.viewer || !width || !height) {
      return;
    }

    let bounds;
    if (poi.bounds?.x) {
      bounds = currentImage.imageToViewportRectangle(
        new OpenSeadragon.Rect(
          poi.bounds.x * width,
          poi.bounds.y * height,
          poi.bounds.width * width,
          poi.bounds.height * height
        )
      )
    } else {
      const zoom = poi.zoom ?? 2;
      bounds = currentImage.imageToViewportRectangle(
        new OpenSeadragon.Rect(
          poi.position.x * width - (width / zoom / 2),
          poi.position.y * height - (width / zoom / 2),
          width / poi.zoom,
          width / poi.zoom
        )
      );
    }
    this.viewer.viewport.fitBoundsWithConstraints(bounds, false);
  }

  /**
   * On Selected Annotation Change
   * Sets the Viewport to the selected Marker
   *
   * @param marker: FlatModels.AnnotationEntityModels.Annotation
   */
  public onSelectedAnnotationChange(marker: FlatModels.AnnotationEntityModels.Annotation): void {
    const currentImage = this.viewer?.world?.getItemAt(this.imageIndex ?? 0);
    const obj = this.container?.elements?.find(e => e.id === marker.id);

    if (!marker || !currentImage || !obj?.element?.handleBounds) return;

    const padding = { x: 0.4, y: 0.2 };
    const bounds = obj.element.handleBounds;
    const viewportBounds = currentImage.imageToViewportRectangle(
      new OpenSeadragon.Rect(
        bounds.x - (bounds.width * padding.x), 
        bounds.y - (bounds.height * padding.y), 
        bounds.width + (bounds.width * padding.x * 2), 
        bounds.height + (bounds.height * padding.y * 2), 
      )
    );

    this.viewer.viewport.fitBoundsWithConstraints(viewportBounds, false);
  }

  /**
   * Go To Link
   *
   * @param id: string
   */
  public goToLink(id: string) {
    const url: string = this.router.serializeUrl(this.router.createUrlTree(['folien', id]));
    window.open(url, '_blank');
  }

  /**
   * Go To External Link
   *
   * @param url: string
   */
  public goToExternalLink(url: string): void {
    window.open(url, '_blank');
  }

  /**
   * On Right Click
   *
   * @param event: Event
   */
  @HostListener('contextmenu', ['$event'])
  public onRightClick(event: Event) {
    event.preventDefault();
  }

  /**
   * Handle Key Up
   *
   * @param event: KeyboardEvent
   */
  @HostListener('window:keyup', ['$event'])
  public handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'Alt' && this.viewer) {
      event.preventDefault();
      this.viewer.gestureSettingsMouse.scrollToZoom = true;
    }
  }

  /**
   * Handle Key Down
   *
   * @param event: KeyboardEvent
   */
  @HostListener('window:keydown', ['$event'])
  public handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Alt' && this.viewer) {
      event.preventDefault();
      this.viewer.gestureSettingsMouse.scrollToZoom = false;
    }

    if (this.mouseOverCanvas) {
      switch (event.key) {
        case 'PageUp':
        case 'k':
          this.slideViewerFacade.requestSetImageIndex(this.imageIndex - 1);
          break;
        case 'PageDown':
        case 'j':
          this.slideViewerFacade.requestSetImageIndex(this.imageIndex + 1);
          break;

        case 'z':
          this.menuViewerFacade.requestToggleVirtualPointer();
          break;
      }

      if (event.altKey) {
        switch (event.key) {
          case 'ArrowUp':
            this.slideViewerFacade.requestSetImageIndex(this.imageIndex - 1);
            break;
          case 'ArrowDown':
            this.slideViewerFacade.requestSetImageIndex(this.imageIndex + 1);
            break;
        }
      }
    }
  }

  /**
   * Handle Wheel
   *
   * @param event: WheelEvent
   */
  public handleWheel(event: WheelEvent) {
    if (event.altKey) {
      event.stopImmediatePropagation();
      const delta = Math.sign(event.deltaY);
      this.slideViewerFacade.requestSetImageIndex(this.imageIndex + delta);
    }
  }

  /**
   * Ng On Destroy
   */
  public ngOnDestroy(): void {
    window.removeEventListener('wheel', this.handleWheel);
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

}
